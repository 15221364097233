<template>
  <b-row class="justify-content-center">
    <b-col
      cols="12"
      md="4"
      lg="4"
      v-if="permissions.includes('sales-order-inquiry')"
    >
      <router-link to="/sales/orders">
        <statistic-card-horizontal
          id="dashboard-statistic"
          icon="ShoppingBagIcon"
          :statistic="statistic.transactions"
          statistic-title="Today Transaction"
        />
      </router-link>
    </b-col>
    <b-col
      cols="12"
      md="4"
      lg="4"
      v-if="permissions.includes('sales-order-inquiry')"
    >
      <router-link to="/sales/orders">
        <statistic-card-horizontal
          id="dashboard-statistic"
          icon="ShoppingBagIcon"
          :statistic="formatRupiah(statistic.amount)"
          statistic-title="Today Income"
        />
      </router-link>
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol, BCard, BCardText, BLink } from "bootstrap-vue";
import StatisticCardHorizontal from "@core/components/statistics-cards/StatisticCardHorizontal.vue";
import moment from "moment";

export default {
  components: {
    StatisticCardHorizontal,
    BRow,
    BCol,
    BCard,
    BCardText,
    BLink,
  },
  data() {
    return {
      statistic: {
        transactions: 0,
        amount: 0,
      },
      permissions: [],
    };
  },
  created() {
    this.getAllPermissions();
    this.getOrderStatistic();
  },
  methods: {
    formatRupiah(money) {
      return new Intl.NumberFormat("id-ID", {
        style: "currency",
        currency: "IDR",
        minimumFractionDigits: 0,
      }).format(money);
    },
    moment: function () {
      return moment();
    },
    getAllPermissions() {
      this.permissions = sessionStorage.getItem("permissions");
    },
    getOrderStatistic() {
      this.$http.get("sales/orders/statistic").then((response) => {
        // console.log(response.data.data)
        this.statistic.transactions = response.data.data.transaction;
        this.statistic.amount = response.data.data.amount;
      });
    },
  },
};
</script>

<style>
div.card#dashboard-statistic {
  border-left: 5px solid #2296d4;
}

div.card#dashboard-statistic:hover {
  border-left: 10px solid #2296d4;
}
</style>
